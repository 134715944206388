body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vh;
}

.drop_area {
  /* width: 350px; */
  background-color: red;
  height: 100px;
  /* box-sizing: border-box; */
}

.ModelView {
  width:80% !important;
  max-width: 80% !important;
  margin-left:10%;
}


.appBar {
  position: relative !important;
  height:3% !important;
}

.closeButton {
  position: absolute !important;
  right: 8px !important;
  color:black !important;
  bottom: 4px;
}

.sendTypo {
  padding: 12px !important;
}

.appBarSend {
  position: relative !important;
  padding:0% !important;
}

.closeButtonSend {
  float: right;
}

/**
* ==============================================
* Dot Loading
* ==============================================
*/

.AlignLoad {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
  }
  
  .dotelastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2065D1;
  color: #2065D1;
  animation: dotElastic 1s infinite linear;
  }
  
  .dotelastic::before, .dotelastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  }
  
  .dotelastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2065D1;
  color: #2065D1;
  animation: dotElasticBefore 1s infinite linear;
  }
  
  .dotelastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2065D1;
  color: #2065D1;
  animation: dotElasticAfter 1s infinite linear;
  }
  
  @keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
  }
  
  @keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
  }
  
  @keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
  }

  /* ///////////////////////////////////////////////////////////////////////////////////////////////////////////// */

  .draw {
    border-radius: 10px;
    overflow: hidden;
    /* padding: 2rem; */
    position: relative;
    z-index: 0;
  }

  .draw::before {
    content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      /* width: 200%; */
      /* height: 200%; */
      background-color: rgba(255, 193, 7, 0.16);
      /* background-repeat: no-repeat; */
      background-position: 0 0;
      background-image: conic-gradient(transparent, #B78103, transparent 30%);
      animation: rotate 4s linear infinite;
  }
  .draw::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    /* width: calc(100% - 12px); */
    /* height: calc(100% - 12px); */
    background: #000;
    border-radius: 5px;
  }


  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
  

  /* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */


  /* body {
    padding: 50px;
  }
  
  .content-counter h3 {
    font-size: emCalc(44);
    line-height: 1.2;
    word-wrap: break-word;
    text-align: right;
    margin-top: 0;
    margin-bottom: emCalc(5);
    padding-bottom: emCalc(5);
    border-bottom: 2px solid black;
    overflow: hidden;
  }
  
  .counter-block {
    color: white;
    font: normal normal 100 80px/1 Helvetica, sans-serif;
    background: black;
    position: relative;
  }
  .counter-block strong,
  .counter-block em {
    font-weight: 100;
    font-style: normal;
    display: inline-block;
  }
  .counter-block em {
    letter-spacing: 5px;
    font-size: 50px;
  }
  .counter-block span {
    padding: 30px 10px 40px 10px;
    display: inline-block;
  }
  .counter-block .counter-text {
    float: right;
    padding-right: 20px;
    position: relative;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  }
  .counter-block .counter-text .counter-ruler {
    position: absolute;
    top: 0;
    right: 105%;
    bottom: 0;
    width: 15%;
    background-color: black;
    background-image: linear-gradient(white 0, white 2%, transparent 2%), linear-gradient(90deg, transparent 70%, black 70%), linear-gradient(transparent 20%, white 20%, white 21%, transparent 21%, transparent 40%, white 40%, white 41%, transparent 41%, transparent 60%, white 60%, white 61%, transparent 61%, transparent 80%, white 80%, white 81%, transparent 81%);
    background-size: 100% 100px;
    background-repeat: repeat-y;
  }
  .counter-block:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 100%;
    height: 0;
    width: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid black;
    margin-top: -12px;
  }
  
  @-webkit-keyframes countdown {
    from {
      transform: translateY(300%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes countdown {
    from {
      transform: translateY(300%);
    }
    to {
      transform: translateY(0);
    }
  }
  .counter-number {
    position: relative;
    transform: translate3d(0, 300%, 0);
    -webkit-animation: countdown 1s forwards;
            animation: countdown 1s forwards;
  }
  .counter-number:nth-of-type(1) {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  .counter-number:nth-of-type(2) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .counter-number:nth-of-type(3) {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  .counter-number i {
    position: absolute;
  }
  .counter-number i:nth-child(1) {
    top: -100%;
  }
  .counter-number i:nth-child(2) {
    top: -200%;
  }
  .counter-number i:nth-child(3) {
    top: -300%;
  }
  .counter-number i:nth-child(4) {
    top: -400%;
  }
  .counter-number i:nth-child(5) {
    top: -500%;
  }
  .counter-number i:nth-child(6) {
    top: -600%;
  }
  .counter-number i:nth-child(7) {
    top: -700%;
  }
  .counter-number i:nth-child(8) {
    top: -800%;
  }
  .counter-number i:nth-child(9) {
    top: -900%;
  }
  
  .cf:before,
  .cf:after {
    content: " ";

    display: table;

  }
  
  .cf:after {
    clear: both;
  }
  
  .cf {
    *zoom: 1;
  } */


  /* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

  /* .Counter-unit {
    transform: translateY(-100px);
    transition: transform 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  } */

  /* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

  .numCounter {
    display: inline-block;
    height: 90px;
    line-height: 90px;
    text-shadow: 0 0 2px #fff;
    font-weight: bold;
    white-space: normal;
    font-size: 50px;
  }
  
  .numCounter > div {
    display: inline-block;
    vertical-align: top;
    height: 100%;
  }
  
  .numCounter > div > b {
    display: inline-block;
    width: 40px;
    height: 100%;
    margin: 0 0.1em;
    border-radius: 8px;
    text-align: center;
    background: white;
    overflow: hidden;
  }
  
  .numCounter > div > b::before {
    content: ' 0 1 2 3 4 5 6 7 8 9 ';
    display: block;
    word-break: break-all;
    -webkit-transition: 0.5s cubic-bezier(0.75, 0.15, 0.6, 1.15), text-shadow 150ms;
    transition: 0.5s cubic-bezier(0.75, 0.15, 0.6, 1.15), text-shadow 150ms;
  }
  
  .numCounter > div > b.blur {
    text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.2), 
                 0 0.1em 2px rgba(255, 255, 255, 0.6), 
                 0 0.3em 3px rgba(255, 255, 255, 0.3), 
                 0 -0.1em 2px rgba(255, 255, 255, 0.6), 
                 0 -0.3em 3px rgba(255, 255, 255, 0.3);
  }
  
  .numCounter > div > b[data-value="1"]::before { margin-top: -90px; }
  .numCounter > div > b[data-value="2"]::before { margin-top: -180px;}
  .numCounter > div > b[data-value="3"]::before { margin-top: -270px;}
  .numCounter > div > b[data-value="4"]::before { margin-top: -360px;}
  .numCounter > div > b[data-value="5"]::before { margin-top: -450px;}
  .numCounter > div > b[data-value="6"]::before { margin-top: -540px;}
  .numCounter > div > b[data-value="7"]::before { margin-top: -630px;}
  .numCounter > div > b[data-value="8"]::before { margin-top: -720px;}
  .numCounter > div > b[data-value="9"]::before { margin-top: -810px;}
  
  .numCounter > div:nth-last-child(3n)::before {
    content: ",";
    display: inline;
    font-size: 1.1em;
    opacity: .6;
    color: white;
  }
  
  /* html, body {
    height: 100%;
  }
  
  body {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial;
  } */
  
  .numCounter {
    overflow: hidden;
    padding: .4em;
    text-align: center;
   
    border-radius: 16px;
    background: black;
  }
  .numCounter b {
    color: black;
  }

  /* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

  .loaderPending {
    position: relative;
  }
    
  .loaderPending ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: 5px solid #B78103;
      animation: clippath 5s infinite linear;
 }

 .loaderCompleted {
  position: relative;
 }

 .loaderCompleted ::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 5px solid #229a16;
  animation: clippath 5s infinite linear;
}
 
 @keyframes clippath {
  /* from {
    clip-path: inset(0 95% 0 0);
  }

  to {
    clip-path: inset(0 0 0 95%);
  } */
  0%,
  100% {
      clip-path: inset(0 0 95% 0);
  }
  25% {
      clip-path: inset(0 95% 0 0);
  }
  50% {
      clip-path: inset(95% 0 0 0);
  } 
   75% {
      clip-path: inset(0 0 0 95%);
  }
}
 
/* //////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.FilpCounter {
  text-align: center;
  width: auto;
  display: flex;
  align-items: center;
}

.FilpCounterDashboard {
  text-align: center;
  width: 100%;
}

.FilpCounter-Font {
  font-size: large;
  font-weight: bolder;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.Title-Dashboard {
  background-color: #103996;
  color: white;
  animation-name: reducetime;
  animation-duration: 1s;
}

@keyframes reducetime {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
 
.flagIcon {
  margin-right: 10px;
  display: inline-flex;
  z-index:1800;
  position: relative;
  border: 1px solid black;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.TextExtract-Sticky {
  width: fit-content;
  position:sticky;
  z-index: 2000;
  padding: 8px !important;
  background: #fff;
}

.TextExtract-StickyTable {
  width: fit-content;
  position:sticky;
  z-index: 2000;
  padding: 8px !important;
  background: #fff;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  background-color: gray;
  transform: translate(-50%, -50%);
}

#flip-flop {
  position: relative;
}

.right {
  text-align: right;
}

#flip-flop::after {
  content: " right!";
}

.right #flip-flop::after {
  content: " left!";
}